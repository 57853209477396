/* @font-face {
    font-family: Poppins;
    src: url('./Gotham/Gotham-Bold.eot');
    src: local('Gotham Bold'), local('Gotham-Bold'),
        url('./Gotham/Gotham-Bold.eot?#iefix') format('embedded-opentype'),
        url('./Gotham/Gotham-Bold.woff2') format('woff2'),
        url('./Gotham/Gotham-Bold.woff') format('woff'),
        url('./Gotham/Gotham-Bold.ttf') format('truetype'),
        url('./Gotham/Gotham-Bold.svg#Gotham-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: Poppins;
    src: url('./Gotham/Gotham-Book.eot');
    src: local('Gotham Book'), local('Gotham-Book'),
        url('./Gotham/Gotham-Book.eot?#iefix') format('embedded-opentype'),
        url('./Gotham/Gotham-Book.woff2') format('woff2'),
        url('./Gotham/Gotham-Book.woff') format('woff'),
        url('./Gotham/Gotham-Book.ttf') format('truetype'),
        url('./Gotham/Gotham-Book.svg#Gotham-Book') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: Poppins;
    src: url('./Gotham/Gotham-Medium.eot');
    src: local('Gotham Medium'), local('Gotham-Medium'),
        url('./Gotham/Gotham-Medium.eot?#iefix') format('embedded-opentype'),
        url('./Gotham/Gotham-Medium.woff2') format('woff2'),
        url('./Gotham/Gotham-Medium.woff') format('woff'),
        url('./Gotham/Gotham-Medium.ttf') format('truetype'),
        url('./Gotham/Gotham-Medium.svg#Gotham-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
} */
