@use 'sass:list';

$text-color: var(--text-color);
$text-color-active: var(--text-color-active);
$text-color-white: var(--text-color-white);
$bgnd-color: var(--bgnd-color);

$menu-toggle-background: #00253e;

// <------ HEADER ------>

#kt_app_sidebar_mobile_toggle,
#kt_app_sidebar_toggle {
    display: inline-block;
    position: relative;
    overflow: hidden;
    margin: 0;
    padding: 0;
    width: 26px;
    height: 26px;
    font-size: 0;
    text-indent: -9999px;
    appearance: none;
    box-shadow: none;
    border-radius: 0;
    border: none;
    cursor: pointer;
    background: none;
    outline: none !important;
    transition: all 0.4s ease;
    line-height: 0;
    vertical-align: middle;
    cursor: pointer;

    span {
        background: $menu-toggle-background;
        display: block;
        position: absolute;
        top: 13px;
        height: 1px;
        min-height: 1px;
        width: 100%;
        border-radius: 0px;
        transition: all 0.4s ease;

        &::before,
        &::after {
            content: '';
            position: absolute;
            display: block;
            height: 1px;
            min-height: 1px;
            content: '';
            border-radius: 0px;
            transition: all 0.4s ease;
            left: 0px;
            background: inherit;
        }

        &::before {
            right: auto;
            width: 50%;
            top: -7px;
        }

        &::after {
            right: auto;
            width: 75%;
            bottom: -7px;
        }
    }

    &.active {
        &::before {
            right: auto;
            width: 50%;
        }

        &::after {
            right: auto;
            width: 75%;
        }
    }

    &:hover {
        span::before,
        span::after {
            width: 100%;
        }
    }
}

#kt_app_header {
    left: 0;
    box-shadow: 0 1px 0 #eff0f3 !important;
    z-index: 1000;
    background: #fff;
    --kt-app-header-height: 70px;
}

#kt_app_sidebar_menu_wrapper {
    margin: 0 !important;
}

.system-title-conatainer {
    padding-left: 30px;
    font-size: 16px;
    font-weight: 500;
    white-space: nowrap;
    margin: 15px 0;
    will-change: transform;
}

body[data-kt-app-sidebar-minimize="on"] {
    .system-title-conatainer {
        transform: rotate(-90deg);
        margin: 0;
        padding-left: 50px;
    }
}

side-bar-menu {
    display: grid;
    height: 100%;
    grid-template-rows: 1fr auto;

    body[data-kt-app-sidebar-minimize="on"] & {
        grid-template-rows: 1fr 200px;
    }
}

#kt_app_sidebar_menu {
    overflow: auto;
    padding: 30px 0 30px 0;
}

#kt_app_sidebar {
    top: 70px;
    --kt-app-sidebar-width-actual: 255px;

    .menu .menu-item {
        margin: 0;
        padding: 0;

        .menu-link {
            cursor: pointer;
            height: 44px;
            padding: 9px 25px;
            font-size: 14px;
            line-height: 24px;
            background: transparent;
            position: relative;
            color: var(--text-color) !important;
            font-weight: 400;

            .menu-title {
                font-weight: 500;
            }

            .menu-icon {
                width: 35px;
                margin-right: 0;
                display: inline-flex;
                align-items: center;
                justify-content: flex-start;

                &,
                i {
                    color: #a8aabb;
                    text-align: left;
                    font-size: 1.4rem;
                }

                i::before {
                    font-size: 25px;
                    color: #00253e;
                }

                i.jour-menu-icon::before {
                    font-size: 30px;
                }
            }

            &.active {
                background: transparent;

                .menu-icon {
                    &,
                    i {
                        color: var(--text-color-active) !important;
                    }

                    i::before {
                        color: var(--text-color-active) !important;
                    }
                }

                &.without-sub::before {
                    background: var(--text-color-active);
                    border-radius: 152px;
                    content: ' ';
                    display: block;
                    height: 100%;
                    position: absolute;
                    width: 10px;
                    z-index: 1;
                    left: -5px;
                }
            }
        }

        .menu-subnav .menu-link {
            padding: 0 30px;
            padding-left: 45px;
            height: 40px;
        }
    }
}

#kt_app_header_container {
    padding: 0 5px !important;
}

#kt_app_sidebar_logo {
    padding-left: 30px !important;
    display: block;

    default-brand {
        margin-left: 26px;

        default-logo img {
            margin: 5px;
        }
    }

    @media (max-width: 991.98px) {
        #kt_app_sidebar_toggle {
            display: none;
        }
    }
}

#notificationIcon {
    color: #00263e !important;
    font-size: 22px;
    font-weight: 500;
    line-height: 14px;
    text-align: right;
    background-color: transparent !important;

    i {
        color: inherit;
        font-size: inherit;

        &::before {
            font-weight: bold;
            padding: 1px;
        }
    }
}

#kt_app_header_wrapper > .app-navbar {
    margin: 0 15px 0 30px;

    > * {
        padding: 0 12px;
    }
}

#kt_quick_user_toggle {
    > div {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .m-topbar__username {
        display: flex !important;
        flex-direction: column;
        align-items: center;
        font-family: Now-Regular;
        font-weight: 600;
        font-size: 20px;
        text-transform: uppercase;
        margin-right: 10px;
        color: #00263e !important;
        line-height: 14px;
        text-align: right;

        > div:first-child {
            font-weight: 600;
            font-size: 20px;
            letter-spacing: 1px;
        }

        > div:last-child {
            font-weight: 600;
            font-size: 20px;
            line-height: 21px;
            letter-spacing: 1px;
        }
    }

    img {
        display: block;
        max-width: 41px !important;
        margin: 0 !important;
        border-radius: 50%;
    }
}

.user-menu__header {
    padding: 20px;
}

.user-menu__profile-picture {
    margin-right: 20px;
    width: 70px;

    img {
        max-width: 70px !important;
        margin: 0 !important;
        border-radius: 100%;
        text-align: center;
    }
}

.user-menu__user-name {
    display: block;
    padding: 0;
    font-weight: 400;
    word-break: break-word;
    font-size: 1.1rem;
    color: #fff !important;
}

.user-menu__body {
    padding: 20px;

    .menu-item {
        padding: 9px 0;
    }

    .menu-link {
        margin: 0;
        padding: 0 !important;
        font-size: 1rem;

        color: #6f727d !important;
        font-weight: 400;
        padding-right: 0.8rem !important;
        background-color: transparent !important;

        .menu-icon {
            width: 35px !important;
            text-align: left;

            i,
            & {
                color: #192e57 !important;
                font-size: 24px !important;
            }
        }

        .menu-title {
            color: inherit !important;
        }

        &:hover {
            color: #00253e !important;
        }
    }
}

user-menu {
    .menu {
        width: 100%;
        max-width: 325px;
    }
}

// <!----- END HEADER ------>

// <!----- ALERTS ------>

.swal2-container {
    .swal2-icon.swal2-warning {
        border-color: #facea8;
        color: #f8bb86;
    }

    .swal2-styled {
        margin: 0.3125em;
        padding: 0.625em 2em;
        box-shadow: none;
        font-weight: 500;
    }

    .swal2-styled.swal2-confirm {
        border: 0;
        border-radius: 0.25em;
        background: #3085d6;
        color: #fff;
        font-size: 1.0625em;
    }

    .swal2-styled.swal2-cancel {
        border: 0;
        border-radius: 0.25em;
        background: #aaa;
        color: #fff;
        font-size: 1.0625em;
    }
}

// <!----- END ALERTS ------>

// <!----- FORMS ----->

body {
    .form-group {
        margin-bottom: 1rem;

        .form-label,
        label {
            font-size: 14px !important;
            line-height: 21px !important;
            color: #3f4047;
            display: inline-block;
            margin-bottom: 0.5rem;
            font-weight: inherit;
        }
    }

    .form-label {
        font-size: 14px !important;
        line-height: 21px !important;
        color: #3f4047;
        display: inline-block;
        margin-bottom: 0.5rem;
        font-weight: inherit;
    }

    .form-control {
        appearance: auto;
    }

    .m-checkbox-list,
    .m-radio-list {
        padding: 0 0;
    }

    .m-checkbox,
    .m-radio {
        padding-left: 22px !important;
    }

    .m-radio,
    .m-checkbox {
        display: inline-block;
        position: relative;
        padding-left: 30px;
        margin-bottom: 10px;
        cursor: pointer;
        font-size: 1rem;
        transition: all 0.3s;
    }

    .m-checkbox {
        color: #575962;
    }

    .m-checkbox-list .m-checkbox,
    .m-checkbox-list .m-radio,
    .m-radio-list .m-checkbox,
    .m-radio-list .m-radio {
        display: block;
    }

    .m-checkbox-list .m-checkbox:last-child,
    .m-checkbox-list .m-radio:last-child,
    .m-radio-list .m-checkbox:last-child,
    .m-radio-list .m-radio:last-child {
        margin-bottom: 5px;
    }

    .m-radio > input,
    .m-checkbox > input {
        position: absolute;
        z-index: -1;
        opacity: 0;
        filter: alpha(opacity=0);
    }

    .m-radio > span,
    .m-checkbox > span {
        border-radius: 3px;
        background: none;
        position: absolute;
        top: 1px;
        left: 0;
        height: 18px;
        width: 18px;
    }

    .m-checkbox > span {
        border: 1px solid #bdc3d4;
    }

    .m-radio > input:checked ~ span,
    .m-checkbox > input:checked ~ span {
        -webkit-transition: all 0.3s;
        transition: all 0.3s;
        background: none;
    }

    .m-radio:hover > input:not([disabled]):checked ~ span,
    .m-radio > input:checked ~ span,
    .m-checkbox:hover > input:not([disabled]):checked ~ span,
    .m-checkbox > input:checked ~ span {
        -webkit-transition: all 0.3s;
        transition: all 0.3s;
    }

    .m-checkbox > input:checked ~ span {
        border: 1px solid #bdc3d4;
    }

    .m-radio > span:after,
    .m-checkbox > span:after {
        content: '';
        position: absolute;
        display: none;
    }

    .m-checkbox > span:after {
        top: 50%;
        left: 50%;
        margin-left: -2px;
        margin-top: -6px;
        width: 5px;
        height: 10px;
        border-width: 0 2px 2px 0 /*rtl:ignore*/ !important;
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg) /*rtl:ignore*/;
    }

    .m-checkbox > span:after {
        border: solid #7281a4;
    }

    .m-radio > input:checked ~ span:after,
    .m-checkbox > input:checked ~ span:after {
        display: block;
    }
}

// <!----- END FORMS ----->

// <!----- BUTTONS ----->
body {
    .btn {
        display: inline-block;
        font-weight: 400;
        text-align: center;
        white-space: nowrap;
        vertical-align: middle;
        user-select: none;
        border: 1px solid transparent;
        padding: 0.85rem 1.15rem;
        font-size: 1rem;
        line-height: 1.25;
        border-radius: 0.25rem;
        transition: none;
    }

    .btn:not(.btn-outline):not(.btn-dashed):not(.border-hover):not(.border-active):not(.btn-flush):not(.btn-icon) {
        padding: 0.85rem 1.15rem;
    }

    .btn-primary {
        color: #fff;
        background-color: var(--text-color-active) !important;
        border-color: var(--text-color-active) !important;
    }

    .btn {
        font-family: 'Poppins';
        box-shadow: none !important;
        cursor: pointer;
        border-radius: 0.25rem;
    }

    .btn.btn-primary {
        color: #fff;
    }

    .btn-sm,
    .btn-group-sm > .btn {
        padding: 0.45rem 0.8rem;
        font-size: 0.875rem;
        line-height: 1.5;
        border-radius: 0.2rem;
    }

    .btn.btn-default,
    .btn.btn-default:hover,
    .btn.btn-secondary,
    .btn.btn-secondary:hover {
        &:not(:disabled) {
            color: #212529;
        }

        background: #fff !important;
        border: 1px solid #eff0f3 !important;

        --kt-secondary-inverse: #212529;
        --kt-secondary-active: #fff;
    }

    .btn.m-btn--custom {
        padding: 0.75rem 2rem;
        font-size: 1rem;
        font-weight: 400;
        font-family: Poppins;
    }

    .btn.m-btn--pill {
        border-radius: 60px;
    }

    .btn.m-btn--bolder {
        font-weight: 500;
    }

    .btn.m-btn--label-brand {
        color: #00253e;
    }
}
// <!----- END BUTTONS ----->

// <!----- ICONS ----->

body .pi {
    font-size: 1.25em;
}

// <!----- END ICONS ----->

// <!----- GENERAL ----->

html,
body {
    font-weight: 300 !important;
    --kt-primary-active: var(--text-color-active);
    --kt-app-bg-color: #f8f8fb;
}

body {
    .p-component {
        font-family: 'Poppins', 'Roboto';
        font-size: 14px;
        text-decoration: none;
    }

    .m-content {
        padding: 15px 15px !important;
    }
}

// Generate helpers for margins and padding that look like:
// mr-1, pl-2, etc.
$sizes: 0, 0.25rem, 0.5rem, 1rem, 1.5rem, 3rem;

$properties: (
    'm': 'margin',
    'p': 'padding',
);

$directionsMap: (
    'l': 'left',
    'r': 'right',
);

@each $propertySelector, $propertyName in $properties {
    @each $directionSelector, $direction in $directionsMap {
        @each $value in $sizes {
            $index: list.index($sizes, $value) - 1;

            .#{$propertySelector}#{$directionSelector}-#{$index} {
                #{$propertyName}-#{$direction}: $value !important;
            }
        }

        .#{$propertySelector}#{$directionSelector}-auto {
            #{$propertyName}-#{$direction}: auto !important;
        }
    }
}

// <!----- END GENERAL ----->

// <!----- MODALS ----->

.modal-title {
    margin-bottom: 0;
    line-height: 1.5;
    font-size: 1.4rem !important;
    color: #3f4047;
    font-weight: 400;
}

.modal-content {
    .close {
        float: right;
        font-size: 1.5rem;
        font-weight: 700;
        line-height: 1;
        color: #000;
        text-shadow: 0 1px 0 #fff;
        opacity: 0.5;
    }
    button.close {
        padding: 0;
        background-color: transparent;
        border: 0;
        -webkit-appearance: none;
    }
    .close:not(:disabled):not(.disabled) {
        cursor: pointer;
    }
}

// <!----- END MODALS ----->

// <!----- BADGES ----->
body {
    .m-badge,
    .badge {
        background: #eaeaea;
        color: #444;
        font-size: 0.8rem;
        line-height: 20px;
        min-height: 20px;
        min-width: 20px;
        vertical-align: middle;
        text-align: center;
        display: inline-block;
        padding: 0px 3px;
        border-radius: 0.75rem;
        font-weight: inherit;

        &.m-badge--wide {
            letter-spacing: 0.6px;
            padding: 1px 10px;
            border-radius: 0;
            border-radius: 0.75rem;
        }
    }

    .m-badge.m-badge--success,
    .badge.badge-success {
        background-color: #34bfa3;
        color: #fff;
    }

    .m-badge.m-badge--brand,
    .badge.badge-primary {
        background-color: #00253e;
        color: #fff;
    }

    .m-badge.m-badge--metal .badge.badge-dark {
        background-color: #c4c5d6;
        color: #fff;
    }
}

// <!----- END BADGES ----->

// <!----- CONTENT ----->

body {
    .m-subheader__title {
        font-size: 20px !important;
        line-height: 24px !important;
        display: inline-block;
        padding: 7px 25px 7px 0;
        font-family: Roboto;
        font-weight: 500;
        vertical-align: middle;
        margin: 0 15px 0 0;
        color: #3f4047;

        .page-heading {
            font-size: inherit;
            line-height: inherit;
            font-weight: inherit;
            color: inherit;
        }
    }

    .subheader-separator {
        border-right: 1px solid #e2e5ec;
    }
}

// <!----- END CONTENT ----->

// <!--- TABLES --->

.primeng-datatable-container {
    .p-datatable table {
        border-collapse: collapse;
        width: 100%;
        table-layout: fixed;

        tbody tr {
            background-color: #fff;
            color: #333;

            &:nth-child(even) {
                background-color: #f3f7fb !important;
            }
        }

        td {
            overflow-wrap: break-word;
            font-family: 'Poppins', 'Roboto';
            border: none !important;
            padding: 8px 10px !important;
            background-color: inherit;
            border-bottom: 1px dashed #e4e4e4;
        }
    }
    .p-datatable .p-datatable-thead > tr > th {
        overflow-wrap: break-word;
        font-size: 14px;
        font-family: Poppins;
        line-height: 21px;
        vertical-align: middle;
        border: none !important;
        padding: 10px 0px 10px 10px !important;
        text-align: left;
        font-weight: 600 !important;
        color: var(--text-color);
        background-color: #e6e6e6 !important;

        p-sorticon i {
            color: #848484;
            margin-left: 0 !important;

            &.pi-sort-alt:before {
                content: '\e915';
            }

            &.pi-sort-amount-up-alt:before {
                content: '\e913';
            }

            &.pi-sort-amount-down:before {
                content: '\e914';
            }
        }

        &:hover p-sorticon i {
            color: #333;
        }

        .p-sortable-column-badge {
            display: none;
        }
    }

    .table-action-container i.jour-download {
        font-size: 2.3rem !important;
    }

    .table-action-container i.la-sign-in {
        margin-right: 4px;
    }

    .table-action-container i.la-key,
    .table-action-container i.la-book {
        font-size: 1.7rem !important;
    }

    .table-action-container i.jour-expand {
        margin-left: 4px;
    }

    .table-action-container i.la-book {
        font-size: 1.7rem !important;
    }

    .table-action-container i.jour-manage,
    .table-action-container i.jour-strategy,
    .table-action-container i.jour-expand,
    .table-action-container i.jour-delete,
    .table-action-container i.jour-summary-page,
    .table-action-container i.la-sign-in {
        font-size: 2rem !important;
    }

    .table-action-container i.jour-download {
        font-size: 2.4rem !important;
    }
    .table-action-container i.jour-delete {
        font-size: 2.3rem !important;
    }

    .table-action-container i.la-sign-in,
    .table-action-container i.jour-strategy {
        margin-right: 4px !important;
    }

    .table-action-container i.la-sign-in {
        font-size: 1.8rem !important;
    }

    .table-action-container i.jour-table-edit {
        font-size: 1.7rem !important;
    }

    .table-action-container i.jour-summary-page {
        font-size: 3em !important;
    }

    .table-action-container i.jour-expand {
        margin-left: 4px;
    }

    .table-action-container i.la-book {
        font-size: 1.7rem !important;
    }

    .table-action-container .btn {
        margin: 0px !important;
    }

    .table-action-container i.jour-manage,
    .table-action-container i.jour-strategy,
    .table-action-container i.jour-table-edit,
    .table-action-container i.jour-expand,
    .table-action-container i.jour-delete,
    .table-action-container i.jour-summary-page,
    .table-action-container i.la-sign-in,
    .table-action-container i.jour-download,
    .table-action-container i.jour-add-unit {
        font-size: 2rem !important;
    }

    .table-action-container i.jour-delete {
        font-size: 2.3rem !important;
    }

    .table-action-container i.la-sign-in,
    .table-action-container i.jour-strategy {
        margin-right: 4px !important;
    }

    .table-action-container i.la-sign-in {
        font-size: 1.8rem !important;
    }

    .table-action-container i.jour-summary-page {
        font-size: 3em !important;
    }

    .table-link,
    .table-link:hover {
        color: var(--text-color);
        text-decoration: none;
        cursor: pointer;
        font-size: 14px !important;
        display: contents !important;
    }

    .table-action-container i.flaticon-doc {
        font-size: 1.3rem !important;
        margin-right: 5px;
    }

    .table-action-container i.flaticon-folder-1 {
        font-size: 1.3rem !important;
        margin-right: 5px;
    }

    .table-action-container i.jour-assessments {
        font-size: 1.6rem !important;
        margin-right: 5px;
    }

    .table-clicable-record td {
        vertical-align: top;
    }
}

// <!----- END TABLES ----->

// <!----- ALERTS ----->

.alert {
    position: relative;
    margin-bottom: 1rem;
    border: 1px solid transparent;
    border-radius: 0.25rem;
    padding: 0.85rem 1.5rem;
    border-radius: 4px;
    background: #fff;
}

.alert-warning:not(.m-alert--outline) {
    color: #fff;
    background-color: #ffc241;
    border-color: #ffbd31;
}

.alert.alert-warning {
    color: #333;
}

// <!----- END ALERTS ----->

// <!----- DRAG AND DROP ----->

.cdk-drag.cdk-drag-preview {
    z-index: 1100 !important;
    opacity: 0.3;
}

// <!----- END DRAG AND DROP ----->

// <!----- ACCORDION ----->

body .p-accordion {
    .p-accordion-header a {
        padding: 0.571em 1em !important;
        border: 1px solid #c8c8c8 !important;
        background-color: #f4f4f4 !important;
        color: #333 !important;
        font-weight: 700 !important;
        transition: background-color 0.2s !important;
        border-radius: 0 !important;
    }

    .p-accordion-tab-active .p-accordion-header a {
        background-color: #a6d2ef !important;
        border: 1px solid !important;
        color: var(--text-color) !important;
    }

    .p-accordion-tab {
        box-shadow: none !important;

        .p-accordion-toggle-icon {
            order: initial;
            margin-left: 0;
        }
    }

    .p-accordion-content {
        padding: 0 !important;
        background-color: #fff;
        color: #333;
        border: none !important;
        border-radius: 0 !important;
    }
}

// <!----- END ACCORDION ----->

// <!----- DROPDOWN ----->

body {
    .dropdown-menu > li > a,
    .dropdown-menu > .dropdown-item {
        display: block;
        padding: 0.7rem 1.2rem;
        color: #575962;
        white-space: nowrap;

        &:hover {
            background: #f4f5f8;
            text-decoration: none;
        }
    }

    ul.dropdown-menu {
        padding: 0.5rem 0 !important;
        --bs-dropdown-color: #212529;
        color: #212529;
        background-color: #fff;
        background-clip: padding-box;
        border-radius: 4px;
        border: 0;
        box-shadow: 0px 0px 15px 1px rgba(69, 65, 78, 0.2);
    }

    .dropdown-menu li {
        padding: 0 !important;
    }
}

// <!----- END DROPDOWN ----->
