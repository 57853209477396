@font-face {
    font-family: 'Lemon/Milk';
    src: url('./LemonMilk/LemonMilkbold.eot');
    src: local('Lemon/Milk bold'), local('LemonMilkbold'), local('Lemon/Milk-bold'), local('LemonMilk bold'),
        url('./LemonMilk/LemonMilkbold.eot?#iefix') format('embedded-opentype'),
        url('./LemonMilk/LemonMilkbold.woff2') format('woff2'),
        url('./LemonMilk/LemonMilkbold.woff') format('woff'),
        url('./LemonMilk/LemonMilkbold.ttf') format('truetype'),
        url('./LemonMilk/LemonMilkbold.svg#LemonMilkbold') format('svg');
    font-weight: bold;
    font-style: normal;
}
