// From ABP
:root {
    --kt-progress-bar-color: #ffffff;
    --kt-progress-bar-bg: #009ef7;
}

// From DEV
/* General */

.clickable-item {
    cursor: pointer;
}

.text-bold {
    font-weight: bold;
}

/* Portlet */
.portlet.light>.portlet-title .inputs.inputs-full-width {
    display: block;
    float: none;
}

/* Tree */

.tree-item-icon-color {
    color: #dfba49;
}

//Fix for font-awesome usage on menu (issue #17)
.page-sidebar .page-sidebar-menu>li>a>i.fa,
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu>li>a>i.fa {
    margin-left: 1px;
    margin-right: 4px;
    top: 3px;
}

/* Forms */

.form-group.form-md-line-input.no-hint {
    margin-bottom: 15px;
}

.form-group.form-md-line-input.has-error .form-control {
    border-bottom-color: red;
}

.form-group.form-md-line-input.has-error .help-block-info {
    display: none;
}

/* Portlets */
.portlet.light>.portlet-title.portlet-title-filter {
    border: none;
    margin-bottom: 0;
}

/* Buttons */

button {
    i {
        margin-right: 5px;
    }
}

.btn+.btn {
    margin-left: 5px;
}

/* FamFamFam Flags */
.famfamfam-flags {
    display: inline-block;
}

/* Social Login Icons */
.social-icons li .microsoft {
    background: url(../../assets/common/images/social/microsoft.png) no-repeat;
}

.margin-bottom-0 {
    margin-bottom: 0 !important;
}

/* Material Design form input angular validation colors */

.form-group.form-md-line-input .form-control.ng-touched.ng-invalid {
    border-bottom-color: red;
}

.form-group.form-md-line-input .form-control.ng-touched.ng-invalid.edited:not([readonly])~label:after {
    background-color: red;
}

.page-footer-inner {
    padding-bottom: 10px;
}

.md-shadow-zero-light {
    box-shadow: 0 0 0 rgba(0, 0, 0, 0.1), 0 0 1px rgba(0, 0, 0, 0.18) !important;
}

/* even in RTL languages below types are LTR */
.input-ltr,
input[type="email"],
input[type="url"],
input[type="number"],
input[type="tel"] {
    direction: ltr;
}

.form-group.form-md-line-input .form-control.edited:not([readonly]):not(:focus)~.help-block {
    opacity: 1;
}

/* JS Tree */
.jstree-default .jstree-icon,
.jstree-default-small .jstree-icon,
.jstree-default-large .jstree-icon {
    background-image: url("../../assets/common/images/32px.png");
}

#jstree-dnd.jstree-default .jstree-er,
#jstree-dnd.jstree-default-small .jstree-er,
#jstree-dnd.jstree-default-large .jstree-er {
    background-image: url("../../assets/common/images/32px.png");
    background-repeat: no-repeat;
    background-color: transparent;
}

.jstree-default .jstree-themeicon-custom {
    background-image: none;
}

/* Make user menu wider */
.page-header.navbar .top-menu .navbar-nav>li.dropdown-dark .dropdown-menu {
    width: 250px;
}

/* Fix for issue #407 */
br {
    font-family: Verdana;
}


.margin-top-20 {
    margin-top: 20px
}

.margin-bottom-10 {
    margin-bottom: 10px
}


.html-container p {
    font-family: Poppins;
    margin: 0px;
    padding: 0px;
}

.table-action-container {
    i {
        padding: 0px !important;
        margin: 0px;
        font-size: 2rem !important;
        color: inherit;

        &.edit-control {
            font-size: 1.7rem !important;
        }
    }

    img {
        height: 20px;
        width: 20px;
    }

    .btn {
        background-color: transparent;
        border: none;
        padding: 0px !important;
        text-decoration: none;
        margin-right: 5px;
        color: #00263e !important;
        padding: 0px !important;

        &:hover {
            color: #005084 !important;
            padding: 0px !important;
        }
    }
}

p-table td {
    img {
        height: 30px;
    }

    p,
    ul {
        margin: 0px;
    }

    ul,
    li {
        padding-left: 1.5em;
        list-style-type: none;
    }

    li:not(.ql-direction-rtl)::before {
        margin-left: -1.5em;
        margin-right: 0.3em;
        text-align: right;
    }

    ul>li::before {
        content: '\2022';
    }
}

.ql-editor {
    font-size: 16px;

    strong {
        line-height: 25px;
    }

    li {
        font-weight: 100;
    }
}

.form-group-inline {
    display: flex;
    align-items: center;

    label {
        margin-bottom: 0;
    }

    .form-control {
        width: 60%;
        margin-left: 15px;
    }
}

// .form-group {
//     label {
//         text-transform: capitalize;
//     }
// }

// .m-tabs__item .nav-link span, th {
//     text-transform: capitalize;
// }

// .m-header__top .m-brand__icon.m-brand__toggler.m--visible-desktop-inline-block {
//     display:none !important;
// }

.m-brand .m-brand__logo {
    width: 160px;
    display: flex !important;
    justify-content: center;
    align-items: center;
}

@media (min-width: 576px) {
    .modal-dialog {
        margin: 4.75rem auto !important;
    }
}


@media (min-width: 1025px) {
    .m-dropdown.m-dropdown--align-center.m-dropdown--large .m-dropdown__wrapper {
        margin-left: -271px !important;
    }
}

.late {
    color: #ff6c6c;
    font-weight: 500;
}

.m-checkbox-fix-height {
    height: 17px;
}

.assign-user-link {
    display: flex;
    align-items: center;
    text-decoration: none;
}

.assign-block-user {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    font-size: 0;
    align-items: center;
    margin: 0px 0px 0px 0px;
}

.assign-block-user__avatar {
    width: 34px;
    height: 34px;
    overflow: hidden;
    border-radius: 50%;
    border: 1px solid #e3e3e3;
}

.assign-block-user__avatar user-picture {
    height: 100%;
}

.assign-block-user__title {
    color: #4c5968;
    font-size: 16px;
    margin: 0px 0px 0px 10px;
    white-space: nowrap;
}

.assign-block-user__title i {
    color: #00263e;
    font-size: 16px;
    margin: 0px 0px 0px 5px;
    display: inline-block;
}

.remove-assigned-user-link {
    margin-left: 10px;
}

.assign-block-user__avatar img {
    width: 100%;
}

.row-buttons-container {
    margin-top: 24px;
}

@media (max-width: 600px) {
    .assign-block-user {
        margin: 0px 0px 10px 0px;
    }
}
