@font-face {
  font-family: 'JourneySystem';
  src:  url('fonts/JourneySystem.eot?vlzlka');
  src:  url('fonts/JourneySystem.eot?vlzlka#iefix') format('embedded-opentype'),
    url('fonts/JourneySystem.ttf?vlzlka') format('truetype'),
    url('fonts/JourneySystem.woff?vlzlka') format('woff'),
    url('fonts/JourneySystem.svg?vlzlka#JourneySystem') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="jour-"], [class*=" jour-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'JourneySystem' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.jour-FF__8_My-Documents:before {
  content: "\e931";
}
.jour-FF__2_SEP-Dashboard:before {
  content: "\e932";
}
.jour-FF__1_Human-Quotient-Hub:before {
  content: "\e933";
}
.jour-FF__3_My-Tactical-Vison-Map:before {
  content: "\e939";
}
.jour-FF__4_Measured-Effect-Session:before {
  content: "\e93a";
}
.jour-FF__5_Matters-of-Importance:before {
  content: "\e93b";
}
.jour-FF__6_My-Observation-Zone:before {
  content: "\e93c";
}
.jour-FF__7_90Day-Deco:before {
  content: "\e93d";
}
.jour-FF__9_MP-Access:before {
  content: "\e942";
}
.jour-FF__10_SEP-Curriculum:before {
  content: "\e943";
}
.jour-FF__10a_Curriculum:before {
  content: "\e944";
}
.jour-FF__10b_Assignment:before {
  content: "\e945";
}
.jour-FF__10c_Rituals-of-Self-Order:before {
  content: "\e946";
}
.jour-FF__10d_Resources:before {
  content: "\e947";
}
.jour-FF__10d1_1-3-10-System:before {
  content: "\e948";
}
.jour-FF__10d1_20-20-System:before {
  content: "\e949";
}
.jour-FF__10d1_How-To-Use:before {
  content: "\e94a";
}
.jour-FF__10d1_Rituals-of-Self-Order:before {
  content: "\e94b";
}
.jour-FF__10d1_TVM:before {
  content: "\e94c";
}
.jour-FF__10e_Thought-Leadership:before {
  content: "\e94d";
}
.jour-FF__10e1_Articles:before {
  content: "\e94e";
}
.jour-FF__10e2_Videos:before {
  content: "\e94f";
}
.jour-FF__10e3_Gifted-Book-Series:before {
  content: "\e950";
}
.jour-FF__10e4_HQ-Discovery-Series:before {
  content: "\e951";
}
.jour-FF__10e5_Servant-Leadership:before {
  content: "\e952";
}
.jour-ninety-days:before {
  content: "\e92c";
}
.jour-annual-planner:before {
  content: "\e92d";
}
.jour-due-dilegance:before {
  content: "\e92e";
}
.jour-intake-form:before {
  content: "\e92f";
}
.jour-observation-zones:before {
  content: "\e930";
}
.jour-team-chart:before {
  content: "\e92b";
}
.jour-select-user:before {
  content: "\e915";
}
.jour-edit:before {
  content: "\e916";
}
.jour-clone:before {
  content: "\e917";
}
.jour-strategy:before {
  content: "\e918";
}
.jour-summary-page:before {
  content: "\e919";
}
.jour-manage:before {
  content: "\e91a";
}
.jour-archive:before {
  content: "\e91b";
}
.jour-move:before {
  content: "\e91c";
}
.jour-expand:before {
  content: "\e91d";
}
.jour-confirm:before {
  content: "\e91e";
}
.jour-contract:before {
  content: "\e91f";
}
.jour-add-unit:before {
  content: "\e920";
}
.jour-add-role:before {
  content: "\e921";
}
.jour-delete:before {
  content: "\e922";
}
.jour-zoom-plus:before {
  content: "\e923";
}
.jour-zoom-minus:before {
  content: "\e924";
}
.jour-fullscreen:before {
  content: "\e925";
}
.jour-download:before {
  content: "\e926";
}
.jour-admins:before {
  content: "\e927";
}
.jour-table-impersonate:before {
  content: "\e928";
}
.jour-table-edit:before {
  content: "\e929";
}
.jour-table-delete:before {
  content: "\e92a";
}
.jour-institutions:before {
  content: "\e900";
}
.jour-assessments:before {
  content: "\e901";
  color: #515151;
}
.jour-assessment-strategyES:before {
  content: "\e902";
  color: #515151;
}
.jour-assessment-category:before {
  content: "\e903";
  color: #515151;
}
.jour-administration:before {
  content: "\e904";
  color: #515151;
}
.jour-companies:before {
  content: "\e905";
  color: #515151;
}
.jour-segmentations:before {
  content: "\e906";
  color: #515151;
}
.jour-company-group:before {
  content: "\e907";
  color: #515151;
}
.jour-manage-users:before {
  content: "\e908";
  color: #515151;
}
.jour-settings:before {
  content: "\e909";
  color: #515151;
}
.jour-coaching-sessions:before {
  content: "\e90a";
  color: #515151;
}
.jour-documents:before {
  content: "\e90b";
  color: #515151;
}
.jour-sal:before {
  content: "\e90c";
  color: #515151;
}
.jour-wheel-of-life:before {
  content: "\e90d";
}
.jour-gain-and-looses:before {
  content: "\e90e";
  color: #515151;
}
.jour-approve-access:before {
  content: "\e90f";
  color: #515151;
}
.jour-dna:before {
  content: "\e910";
  color: #515151;
}
.jour-5-whys:before {
  content: "\e911";
  color: #515151;
}
.jour-ADMINS:before {
  content: "\e912";
  color: #515151;
}
.jour-coaches:before {
  content: "\e913";
  color: #515151;
}
.jour-dna-advisors:before {
  content: "\e914";
  color: #515151;
}


body a, body a:focus, body a:hover,
body a i, body a:focus i, body a:hover i {
  color: #00263e;
  text-decoration: none;
}


body i[class^="fa-"], body i[class*=" fa-"],
body i[class^="la-"], body i[class*=" la-"] {
  color: #00263e;
  font-size: 1.2rem;
}

body .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider:before {
  background: #3a87db;
}

body .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
  background: rgb(58 135 219);
}

body .p-inputswitch:not(.p-disabled):hover .p-inputswitch-slider:before {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

body .p-inputswitch.p-inputswitch-checked:not(.p-disabled):hover .p-inputswitch-slider:before {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

body .p-accordion .p-accordion-tab.p-accordion-tab-active {
  margin-bottom: 0px;
}

body.account {
  background-color: #fff;
}

.float-right {
  float: right;
}

.float-left {
  float: left;
}