/* You can add global styles to this file, and also import other style files */
/* 



@font-face {
    font-family: Poppins;
    src: url("assets/fonts/Gotham/Gotham-Bold.ttf");
}

@font-face {
    font-family: Poppins;
    src: url("assets/fonts/Gotham/Gotham-Book.ttf");
}

@font-face {
    font-family: LemonMilkbold;
    src: url("assets/fonts/LemonMilk/LemonMilkbold.ttf");
} */

@font-face {
    font-family: 'Now-Regular';
    src: url('assets/fonts/NowFL/Now-Regular.ttf');
}

@font-face {
    font-family: Gotham-Medium;
    src: url("assets/fonts/Gotham/Gotham-Medium.ttf");
}

:root {
    --text-color: #00263E;
    --text-color-active: #3A87DB;
    --text-color-white: #FFFFFF;
    --bgnd-color: #f8f8fb;
}

body {
    font-family: Poppins !important;
    outline: none;
}

.ql-container {
    font-family: Poppins !important;
}

input, select {
    font-family: Poppins !important;
}

.btn-primary {
    color: #fff;
    background-color: var(--text-color-active) !important;
    border-color: var(--text-color-active) !important;
}

.jrs-with-reg, .menu-item-jrs-with-reg+span {
    font-family: 'Now-Regular';
    font-size: 16px !important;
}

.jrs-with-reg:after, .menu-item-jrs-with-reg+span:after {
    content: '\00AE';
    font-size: 70%;
    vertical-align: super;
}

.m-radio, .m-checkbox {
    padding-left: 22px !important;
}

.m-grid__item.m-grid__item--fluid.m-wrapper {
    background-color: var(--bgnd-color) !important;
}

body .ui-table .ui-sortable-column.ui-state-highlight .ui-sortable-column-icon {
    color: var(--text-color);
}

.dropdown-menu-left {
    transform: translate(-50%, 0%);
}

.m-subheader {
    padding: 15px !important;
    margin: 15px 15px 0px 15px;
    background-color:var(--text-color-white);
    background: var(--text-color-white);
    box-shadow: 0px 1px 0px #EFF0F3;
    border-radius: 4px;
}

.m-subheader .m-subheader__title {
    font-weight: 500;
    font-size: 20px !important;
    line-height: 24px !important;
    color: var(--text-color);
}

.dropdown-menu > .dropdown-item, .dropdown-menu > li > a {
    color: #6f727d;
    font-weight: 400;
}

.ui-sortable-column-icon.pi-sort-down:before {
    content: "\e914";
 }
 
 .ui-sortable-column-icon.pi-sort-up:before {
     content: "\e913";
 }

/* .m-content {
    background: var(--text-color-white);
    box-shadow: 0px 1px 0px #EFF0F3;
    border-radius: 4px;
} */

.primeng-datatable-container .ui-table table thead tr th {
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: var(--text-color);
}

.m-aside-menu.m-aside-menu--skin-light .m-menu__nav > .m-menu__item > .m-menu__link {
    font-size: 14px;
    line-height: 24px;
}

.m-aside-menu.m-aside-menu--skin-light .m-menu__nav > .m-menu__item.m-menu__item--active > a.m-menu__link,
.m-aside-menu.m-aside-menu--skin-light .m-menu__nav>.m-menu__item:not(.m-menu__item--parent):not(.m-menu__item--open):not(.m-menu__item--expanded):not(.m-menu__item--active):hover>.m-menu__heading, .m-aside-menu.m-aside-menu--skin-light .m-menu__nav>.m-menu__item:not(.m-menu__item--parent):not(.m-menu__item--open):not(.m-menu__item--expanded):not(.m-menu__item--active):hover>a.m-menu__link,
.m-aside-menu.m-aside-menu--skin-light .m-menu__nav > .m-menu__item .m-menu__submenu .m-menu__item.m-menu__item--active > a.m-menu__link {
    background-color: transparent !important;
}

.m-aside-menu.m-aside-menu--skin-light .m-menu__nav>.m-menu__item.m-menu__item--active>.m-menu__heading .m-menu__link-text, .m-aside-menu.m-aside-menu--skin-light .m-menu__nav>.m-menu__item.m-menu__item--active>.m-menu__link .m-menu__link-text {
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: var(--text-color-active) !important;
}

.m-aside-menu.m-aside-menu--skin-light .m-menu__nav>.m-menu__item .m-menu__submenu .m-menu__item.m-menu__item--active>.m-menu__heading .m-menu__link-text, .m-aside-menu.m-aside-menu--skin-light .m-menu__nav>.m-menu__item .m-menu__submenu .m-menu__item.m-menu__item--active>a.m-menu__link .m-menu__link-text,
.m-aside-menu.m-aside-menu--skin-light .m-menu__nav>.m-menu__item.m-menu__item--active>.m-menu__heading .m-menu__link-icon, 
.m-aside-menu.m-aside-menu--skin-light .m-menu__nav>.m-menu__item.m-menu__item--active>.m-menu__link .m-menu__link-icon,
.m-aside-menu.m-aside-menu--skin-light .m-menu__nav>.m-menu__item.m-menu__item--active>.m-menu__heading .m-menu__link-icon:before, 
.m-aside-menu.m-aside-menu--skin-light .m-menu__nav>.m-menu__item.m-menu__item--active>.m-menu__link .m-menu__link-icon:before,
.m-aside-menu.m-aside-menu--skin-light .m-menu__nav>.m-menu__item .m-menu__submenu .m-menu__item.m-menu__item--active>.m-menu__heading .m-menu__link-icon, .m-aside-menu.m-aside-menu--skin-light .m-menu__nav>.m-menu__item .m-menu__submenu .m-menu__item.m-menu__item--active>.m-menu__link .m-menu__link-icon:before {
    color: var(--text-color-active) !important;
}

.m-aside-menu.m-aside-menu--skin-light .m-menu__nav>.m-menu__item .m-menu__submenu .m-menu__item.m-menu__item--active {
    position: relative;
}

li.m-menu__item.m-menu__item--open.m-menu__item--expanded.m-menu__item--active:not(.m-menu__item--submenu).ng-star-inserted:before,
.m-aside-menu.m-aside-menu--skin-light .m-menu__nav>.m-menu__item.m-menu__item--active:not(.m-menu__item--submenu):before,
.m-aside-menu.m-aside-menu--skin-light .m-menu__nav>.m-menu__item .m-menu__submenu .m-menu__item.m-menu__item--active:before {
    background: var(--text-color-active);
    border-radius: 152px;
    content: ' ';
    display: block;
    height: 100%;
    position: absolute;
    width: 10px;
    z-index: 1;
    left: -5px;
}

.m-aside-menu.m-aside-menu--skin-light .m-menu__nav>.m-menu__item>.m-menu__heading .m-menu__link-text, .m-aside-menu.m-aside-menu--skin-light .m-menu__nav>.m-menu__item>.m-menu__link .m-menu__link-text,
.m-aside-menu.m-aside-menu--skin-light .m-menu__nav>.m-menu__item .m-menu__submenu .m-menu__item>.m-menu__heading .m-menu__link-text, .m-aside-menu.m-aside-menu--skin-light .m-menu__nav>.m-menu__item .m-menu__submenu .m-menu__item>.m-menu__link .m-menu__link-text {
        color: var(--text-color) !important;
    font-size: 14px;
    line-height: 24px;
}

.m-topbar .m-topbar__nav.m-nav > .m-nav__item > .m-nav__link * {
    color: var(--text-color) !important;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    text-align: right;
}

/* Styles */

body a, body a:hover, body a:focus {
    color: var(--text-color);
    text-decoration: none;
}

body .ui-inputswitch:not(.ui-state-disabled):hover .ui-inputswitch-slider {
    background-color: #007ad9;
}

body .ui-inputswitch.ui-inputswitch-checked:not(.ui-state-disabled):hover .ui-inputswitch-slider {
    background-color: #007ad9;
}
/* End new styles */

.margin-left-5 {
    margin-left: 5px !important;
}

.margin-right-5 {
    margin-right: 5px !important;
}

.margin-top-5 {
    margin-top: 5px !important;
}

.margin-top-15 {
    margin-top: 15px !important;
}

.margin-bottom-5 {
    margin-bottom: 5px !important;
}

.margin-bottom-5 {
    margin-bottom: 5px !important;
}

.margin-bottom-15 {
    margin-bottom: 15px !important;
}

.padding-left-5 {
    padding-left: 5px !important;
}

.padding-right-5 {
    padding-right: 5px !important;
}

.padding-top-5 {
    padding-top: 5px !important;
}

.padding-bottom-5 {
    padding-bottom: 5px !important;
}

.width-percent-5 {
    width: 5%;
}

.width-percent-10 {
    width: 10%;
}

.width-percent-15 {
    width: 15%;
}

.width-percent-20 {
    width: 20%;
}

.width-percent-30 {
    width: 30%;
}

.width-percent-40 {
    width: 40%;
}

.width-percent-50 {
    width: 50%;
}

.width-percent-100 {
    width: 100%;
}

/* Sweet alert */

body.swal2-height-auto, 
body.swal2-shown.swal2-height-auto {
    height: inherit !important;
}

.swal2-popup.swal2-toast {
    padding: 10px 12px !important;
    display: block !important;
}

.swal2-popup.swal2-toast .swal2-header,
.swal2-popup.swal2-toast .swal2-title,
.swal2-popup.swal2-toast .swal2-content {
    text-align: left !important;
    margin: 0 !important;
}

.swal2-popup.swal2-toast .swal2-title {
    margin-bottom: 4px !important;
}

.swal2-icon.swal2-info::before,
.swal2-icon.swal2-warning::before,
.swal2-icon.swal2-success::before,
.swal2-icon.swal2-error::before {
    content: "";
}

@keyframes glowing {
    0% {
        opacity: 0.3;
    }

    50% {
        opacity: 1;
    }

    100% {
        opacity: 0.3;
    }
}

.glowing {
    animation: glowing 2s infinite;
}

.root-initial-loader .overlay {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(51, 51, 51, 0.8);
    z-index: 99999 !important;
    width: 100%;
    height: 100%;
}

.root-initial-loader i {
    position: absolute;
    color: rgb(91, 167, 234);
    z-index: 100000 !important;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.root-initial-loader .fa-spin-2x {
    -webkit-animation: fa-spin 1s infinite linear;
    animation: fa-spin 1s infinite linear;
}
ngx-spinner .loading-text {
    font-weight: bold;
    color: white;
    font-size: 1.3em;
}

bs-days-calendar-view + bs-days-calendar-view {
    margin-left: 10px;
}

bs-days-calendar-view {
    display: inline-block;
    border-radius: 4px 0 0 4px;
}

@media (max-width: 768px) {
    .btn-md-icon {
        margin-right: 0 !important;
        padding-right: 0 !important;
    }
}

html.swal2-height-auto, 
html.swal2-shown.swal2-height-auto {
    height: 100% !important;
}




.assessment-summary{
    min-height: 100%;
    display: block;
    height: 100%;
}
.assessment-summary .main-block {

    background-color: #f8f8fb;
    /* background: url(/assets/common/images/BASECAMP-IMAGE-FINAL-crop.jpg) center bottom no-repeat scroll rgb(74, 155, 237); */
    /* background: url(/assets/common/images/BACKGROUND-LOGIN.jpg) center bottom no-repeat scroll rgb(74, 155, 237); */
    /* background-image: url("./assets/imgs/BASECAMP-IMAGE-FINAL-crop.jpg"); */
    /* background-repeat: no-repeat; */

    overflow: hidden;
    display: flex !important;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    /* background-position: bottom center;
    background-color: #4a9bed; */
}
/* 
@media (min-width: 1440px) {
    .assessment-summary .main-block {
        background-size: cover;
    }
} */

@media (max-width: 1024px) {
    .assessment-summary .main-block .main-block-content {
        flex-direction: column;
    }
}

.assessment-summary .main-block > * {
    position: relative;
    z-index: 2;
}

@media (min-width: 1764px) {
    .assessment-summary .main-block {
        background-size: cover;
    }
}

.assessment-summary .main-block:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    bottom: 0;
    z-index: 1;
    background-color: rgba(253, 254, 255, 0.57);
    /* background-color: rgba(255, 255, 255, 0.8); */
}

.assessment-summary body {
    margin: 0;
    padding: 0;
}

.assessment-summary .container {
    padding: 0 15px;
}


.form-control[readonly], .form-control {
    border: 1px solid #aeaeae !important;
    height: 45px !important;
    font-size: 16px !important;
    color: #333333 !important;
}

.form-control-textarea[readonly], .form-control-textarea {
    border: 1px solid #aeaeae !important;
    font-size: 16px !important;
    color: #333333 !important;
}

/* .m-subheader .m-subheader__title {
    font-size: 24px !important;
    line-height: 30px !important;
} */

.form-group label {
    font-size: 14px !important;
    line-height: 21px !important;
    color: #3f4047;
    display: inline-block;
    margin-bottom: .5rem;
}

.fix-row-margin {
    margin-right: -15px;
    margin-left: -15px;
}


.m-form .m-form__group {
    margin-bottom: 10px !important;
}

.m-subheader {
    padding: 15px !important;
    margin: 15px 15px 0px 15px;
    box-shadow: 0px 1px 15px 1px rgba(69,65,78,0.08);
    background-color: #fff;
}

.search-field-container input {
    flex: 1 0 !important;
}

.trasparent-background {
    background-color: transparent !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
}


.options.inline .option {
    display: inline-block;
    margin: 0px 25px 10px 0px; }
  
  .option {
    position: relative;
    font-size: 16px;
    cursor: pointer;
    padding-left: 30px;
    line-height: 20px;
    margin: 0px 0px 10px 0px; }
    .option:last-child {
      margin: 0px 0px 0px 0px; }
    .option input {
      display: none; }
    .option:before {
      -webkit-transition: all 0.3s ease 0s;
      -moz-transition: all 0.3s ease 0s;
      -ms-transition: all 0.3s ease 0s;
      -o-transition: all 0.3s ease 0s;
      transition: all 0.3s ease 0s;
      position: absolute;
      left: 0;
      top: 0;
      content: '';
      width: 20px;
      height: 20px;
      box-shadow: 1px 1px 3px rgba(204, 204, 204, 0.5);
      border: 1px solid #cccccc;
      background-color: var(--text-color-white);
      border-radius: 50%; }
    .option:after {
      content: '';
      position: absolute;
      border-radius: 50%;
      left: 5px;
      top: 5px;
      opacity: 0;
      -moz-transform: scale(0);
      -ms-transform: scale(0);
      -webkit-transform: scale(0);
      -o-transform: scale(0);
      transform: scale(0);
      -webkit-transition: all 0.3s ease 0s;
      -moz-transition: all 0.3s ease 0s;
      -ms-transition: all 0.3s ease 0s;
      -o-transition: all 0.3s ease 0s;
      transition: all 0.3s ease 0s;
      width: 10px;
      height: 10px;
      border: 1px solid #cccccc;
      background-color: var(--text-color-white); }
    .option.mini {
      font-size: 14px; }
    .option.blue a {
      color: #2a9ddc; }
      .option.blue a:hover {
        text-decoration: underline; }
    .option.blue.active:before {
      border: 1px solid #2a9ddc;
      background: #2a9ddc; }
    .option.active:before {
      box-shadow: 1px 1px 3px rgba(204, 204, 204, 0.5);
      border-radius: 100px;
      border: 1px solid #789b3d;
      background-color: #98c44d; }
    .option.active:after {
      -moz-transform: scale(1);
      -ms-transform: scale(1);
      -webkit-transform: scale(1);
      -o-transform: scale(1);
      transform: scale(1);
      opacity: 1; }


    .tab-in-header-layout .m-content.pt-0 {
        padding-top: 0px !important;
    }
    
    .tab-in-header-layout .m-portlet__body {
        padding: 0px !important;
    }
   

    
    .tab-in-header-layout .table-container {
        padding: 0px;
        display: block;
        background-color: transparent;
    }

    /* .tab-in-header-layout .table-container */
     .white-section {
        padding: 15px;
        background-color: #fff;
        border-radius: 4px;
        margin-bottom: 15px;
        box-shadow: 0px 1px 0px #EFF0F3;
        margin-left: 0 !important;
        margin-right: 0 !important;
    }
    
    .white-section-with-margin {
        margin-left: 15px !important;
        margin-right: 15px !important;
    }

    /* .tab-in-header-layout .table-container */
     .white-section h3 {
        font-size: 18px;
        line-height: 24px;
    }
    
    .tab-in-header-layout .tab-container .nav.nav-tabs {
        background-color: #fff;
        /* box-shadow: 0px 7px 15px 1px rgba(69,65,78,0.08); */
        padding: 0px 10px 10px;
        margin-bottom: 15px;
    }

    .tab-in-header-layout .nav-tabs .nav-link.active, 
    .tab-in-header-layout .nav-tabs .nav-item.show .nav-link {
        position: relative;
        border-color: transparent;
    }
    
    .tab-in-header-layout .m-subheader {
        box-shadow: 0px 1px 15px 1px rgba(69,65,78,0.08);
        padding-bottom: 0px !important;
    }
    
    .tab-in-header-layout .m-portlet.m-portlet--mobile {
        box-shadow: none;
        background-color: transparent;
    }

    .tab-in-header-layout .nav-tabs .nav-link.active:after, 
    .tab-in-header-layout .nav-tabs .nav-item.show .nav-link:after {
        content: '';
        position: absolute;
        width: 60%;
        height: 1px;
        left: 20%;
        /* transform: translateX(-50%) rotate(45deg); */
        bottom: 0px;
        background: rgba(0, 38, 62, 0.54);
    }

    .modal .modal-content .modal-header .modal-title {
        font-weight: 500;
        font-size: 1.4rem !important;
    }

    .modal .modal-content .modal-header {
        padding: 25px;
        display: flex !important;
        align-items: center;
    }

    .modal .modal-content .modal-header .close {
        font-size: 2rem !important;
        opacity: 0.8 !important;
    }

    .modal .modal-content .modal-header .close:before {
        font-size: 2rem !important;
    }

    .input-group span.input-group-btn button {
        height: 45px !important;
    }

    
    .modal-open {
        overflow: hidden !important;
    }

    .swal2-shown.swal2-height-auto {
        overflow: hidden !important;
        height: auto !important;
    }


    .ui-accordion-content.ui-widget-content {
        padding: 0px !important;
    }

    /* ul {
        font-weight: bold;
        margin-top: 10px;
    } */


    .ui-accordion .ui-accordion-header:not(.ui-state-disabled) a {
        font-weight: bold;
    }

    .ui-accordion .ui-accordion-header:not(.ui-state-disabled).ui-state-active a, .ui-accordion .ui-accordion-header:not(.ui-state-disabled).ui-state-active a:hover {
        background-color: #a6d2ef !important;
        border: 1px solid var(--text-color) !important;
        color: var(--text-color) !important;
    }

    .ui-accordion .ui-accordion-header:not(.ui-state-disabled).ui-state-active a .ui-accordion-toggle-icon, .ui-accordion .ui-accordion-header:not(.ui-state-disabled).ui-state-active a:hover .ui-accordion-toggle-icon {
        color: var(--text-color) !important;
    }



    .m-brand {
        width: auto !important;
    }

    .m-brand .m-stack__item--middle.m-brand__logo {
        width: 550px;
        padding-left: 52px !important;
        justify-content: left;
    }

    .m-brand--minimize .m-brand .m-stack.m-stack--general {
        padding-left: 30px !important;
    }

@media (min-width: 1025px) {
    .m-brand--minimize .m-brand .m-stack__item--middle.m-brand__logo {
        display: flex !important;
    }
}

body .ui-widget-content p,
body .ui-widget-content ol,
body .ui-widget-content ul,
body .ui-editor-content p,
body .ui-editor-content ol,
body .ui-editor-content ul,
.inplace-editor .viewBlock .ineLabel {
    font-size: 14px !important;
    line-height: 19px !important;
}

.nav-tabs {
    background-color: #fff;
    border: none;
}

.nav.nav-tabs .nav-link {
    border: none;
}

.nav.nav-pills, .nav.nav-tabs {
    margin-bottom: 15px;
}

.modal .modal-content .modal-header,
.modal .modal-content .modal-body,
.modal .modal-content .modal-footer {
    padding: 15px !important;
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0; 
}

input[type=number] { 
    text-align: center;
}

.dont-break-out {
    /* These are technically the same, but use both */
    overflow-wrap: break-word;
    word-wrap: break-word;
  
    -ms-word-break: break-all;
    /* This is the dangerous one in WebKit, as it breaks things wherever */
    word-break: break-all;
    /* Instead use this non-standard one: */
    word-break: break-word;
  
    /* Adds a hyphen where the word breaks, if supported (No Blink) */
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;
  
  }

select.form-control { 
    transition: none !important; 
}

.noselect {
    -webkit-touch-callout: none; /* iOS Safari */
      -webkit-user-select: none; /* Safari */
       -khtml-user-select: none; /* Konqueror HTML */
         -moz-user-select: none; /* Firefox */
          -ms-user-select: none; /* Internet Explorer/Edge */
              user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome and Opera */
  }

  .m-brand__logo-wrapper img {
      margin: 5px;
  }

.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
    font-weight: 600 !important;
}

.tab-in-header-layout .nav-tabs .nav-link.active, .tab-in-header-layout .nav-tabs .nav-item.show .nav-link {
    font-weight: 600 !important;
}

.text-align-center {
    text-align: center;
}

.flex-direction-column {
    flex-direction: column;
    -webkit-flex-direction: column;
}

.m-header--fixed .m-header {
    background-color: #fff;
}

bs-datepicker-container, bs-daterangepicker-container {
    z-index: 20000;
}

.modal.fade.show {
    background-color: #00000052;
}


.m-menu__link-icon:before {
    font-size: 25px;
    color: #00253e;
}

.jour-menu-icon:before {
    font-size: 30px;
    color: #00253e;
}

.m-nav .m-nav__item > .m-nav__link .m-nav__link-icon {
    color: #192e57 !important;
    font-size: 24px !important;
}

i.fa.fa-expand-arrows-alt {
    font-size: 20px !important;
    padding: 0px 5px;
}



.m-nav .m-nav__item > .m-nav__link .m-nav__link-icon {
    color: #192e57 !important;
    font-size: 24px !important;
}


.modal-backdrop {
    background-color: #fff !important;
}

.modal-backdrop.show {
    opacity: .1 !important;
}

.table-link, .table-link:hover {
    color: var(--text-color);
    text-decoration: none;
    cursor: pointer;
    font-size:14px !important;
    display: contents !important;
}

.justify-content-space-between {
    justify-content: space-between;
}

.ui-table-scrollable-header {
    background-color: transparent !important;
}


.ps__rail-x, .ps__rail-y {
    z-index: 100;
}

.ps--active-x > .ps__rail-x, .ps--active-y > .ps__rail-y {
    background-color: rgb(255, 255, 255) !important;
    opacity: 1 !important;
}

bs-dropdown-container {
    z-index: 10;
}


.m-header--fixed .m-header {
    background-color: #fff;
}

.m-menu__link-icon:before {
    font-size: 25px;
    color: #00253e;
}

.jour-menu-icon:before {
    font-size: 30px;
    color: #00253e;
}

.m-nav .m-nav__item > .m-nav__link .m-nav__link-icon {
    color: #192e57 !important;
    font-size: 24px !important;
}

.justify-content-space-between {
    justify-content: space-between;
}

.principle-details-page .radarChart tspan {
    cursor: default !important;
}

.btn.btn-default, .btn.btn-secondary,
.btn.btn-default:hover, .btn.btn-secondary:hover {
    background: white !important;
    border: 1px solid #EFF0F3 !important;
}

.noselect {
    -webkit-touch-callout: none; /* iOS Safari */
      -webkit-user-select: none; /* Safari */
       -khtml-user-select: none; /* Konqueror HTML */
         -moz-user-select: none; /* Firefox */
          -ms-user-select: none; /* Internet Explorer/Edge */
              user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome and Opera */
}

.positioned-contextmenu .ui-contextmenu {
    margin-left: 0!important;
    margin-top: 0!important;
    left: 0!important;
    top: 0!important;
}

.positioned-contextmenu p-contextmenu {
    position: relative;
}

.user-instruction {
    font-size: 12px;
    font-style: italic;
    text-decoration: none!important;
}

.form-group-inline label {
    margin-bottom: 0px !important;
}

.m-grid__item.m-grid__item--fluid.m-wrapper {
    background-color: #f3f3f4;
}



/* new style */
.nav.nav-pills .nav-link, .nav.nav-tabs .nav-link {
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    color: var(--text-color);
    
}

.nav.nav-pills .nav-link, .nav.nav-tabs .nav-link:hover{
    color: var(--text-color-active);
}

.tab-in-header-layout .nav-tabs .nav-link.active, .tab-in-header-layout .nav-tabs .nav-item.show .nav-link {
    font-weight: 500;
    color: var(--text-color-active);
}

.tab-in-header-layout .nav-tabs .nav-link.active:after, .tab-in-header-layout .nav-tabs .nav-item.show .nav-link:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 2px;
    left: 0;
    /* transform: translateX(-50%) rotate(45deg); */
    bottom: 0px;
    background: var(--text-color-active);
    border-radius: 16px;
}

.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
    border: none;
    font-weight: normal !important;
}

tr th:first-child, tr td:first-child {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
}

tr th:last-child , tr td:last-child {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
}
/* end new style */


body.m-aside-left--skin-light .m-header {
    box-shadow: 0px 1px 0px #EFF0F3 !important;
}

.primeng-datatable-container .primeng-no-data {
    border: none !important;
}

.nav.nav-pills, .nav.nav-tabs {
    margin-bottom: 15px !important;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
}

.more-details-closed {
    margin-top: -15px !important;
}

.padding-15 {
    padding: 15px;
}

.m-aside-menu .m-menu__nav>.m-menu__item>a.m-menu__link {
    padding: 9px 25px;
}

@media (min-width: 1025px) {
    body.m-aside-left--skin-dark .m-header .m-header-head {
        box-shadow: 0px 1px 0px #EFF0F3;
    }
}

.more-details-controls {
    cursor: pointer;
}

.form-control[readonly], .form-control {
    border: 1px solid #EAEAEA !important;
    box-sizing: border-box !important;
    border-radius: 4px !important;
    font-size: 14px !important;
    line-height: 21px !important;
    height: 40px !important;
    padding: 8px 11px !important;
    font-weight: normal !important;
}


p {
    margin-bottom: 3px !important;
}

.radio-container .checkcontainer {
    display: block;
    position: relative;
    padding-left: 25px;
    margin-bottom: 7px;
    cursor: pointer;
    font-size: 14px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.radio-container .checkcontainer input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

.radio-container .checkcontainer input:checked ~ .radiobtn {
    background-color: #ffffff;
}

.radio-container .radiobtn {
    position: absolute;
    top: 0;
    left: 0;
    height: 18px;
    width: 18px;
    background-color: #fff;
    border-radius: 50%;
    border: 0.06em solid #00263e;
}

.radio-container .checkcontainer input:checked ~ .radiobtn:after {
    display: block;
}

.radio-container .checkcontainer .radiobtn:after {
    top: 1px;
    left: 1px;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    background: #00263e;
}

.radio-container .radiobtn:after {
    content: "";
    position: absolute;
    display: none;
}



.black-checkbox-container .checkcontainer {
    display: block;
    position: relative;
    padding-left: 25px;
    margin-bottom: 7px;
    cursor: pointer;
    font-size: 14px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.black-checkbox-container .checkcontainer input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

.black-checkbox-container .checkcontainer input:checked ~ .checkbox {
    background-color: #ffffff;
}

.black-checkbox-container .checkbox {
    position: absolute;
    top: 0;
    left: 0;
    height: 18px;
    width: 18px;
    background-color: #fff;
    border-radius: 5px;
    border: 0.06em solid #00263e;
}

.black-checkbox-container .checkcontainer input:checked ~ .checkbox:after {
    display: block;
}

.black-checkbox-container .checkcontainer .checkbox:after {
    top: 0.1em;
    left: 0.1em;
    width: 1em;
    height: 1em;
    border-radius: 5px;
    background: #00263e;
}

.black-checkbox-container .checkbox:after {
    content: "";
    position: absolute;
    display: none;
}

.table-clicable-record {
    color: var(--text-color) !important;
}

.table-clicable-record:hover{
    font-weight: bold;
    cursor: pointer;
}

.table-circle {
    height: 22px;
    width: 22px;
    border: 1px solid #3a87db;
    border-radius: 50%;
    background-color: #3a87db;
    margin: 0 auto;
}

.ql-snow .ql-editor p+h5, .ql-snow .ql-editor ul+h5 {
    margin-top: 15px;
}

.ql-snow .ql-editor h5 {
    font-size: 14px !important;
}


.m-topbar .m-topbar__nav.m-nav > .m-nav__item > .m-nav__link * {
    font-size: 22px;
}

.swal2-popup.swal2-toast.swal2-noanimation {
    padding: 0.625em !important;
}

p.ql-align-center {
    text-align: center;
}

p.ql-align-right {
    text-align: right;
}

.nav-tabs .nav-item .nav-link.active {
    color: #3a87db;
}

.sal-list-header {
    font-weight: 700;
}

.primeng-datatable-container .ql-editor {
    overflow: inherit;
}

.primeng-datatable-container .ql-editor,
.primeng-datatable-container .ql-editor p {
    font-size:14px;
    line-height: 14px !important;
    margin-bottom: 0px !important;
}

.min-h-150 {
    min-height: 150px;
}

body .ui-table .ui-table-tbody tr.table-clicable-record td {
    vertical-align: top;
}

span.table-tag {
    background-color: #3a87db;
    padding: 2px 6px;
    border-radius:5px;
    color: #fff;
    font-weight:500;
}

.table-tags-container {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
}

.datepicker-wrapper{
    position: relative;
}

.drop-icon {
    position: absolute;
    right: 10px;
    top: 50%;
    font-size: 17px;
    text-decoration: none;
    font-style: normal;
    cursor: pointer;
    transition: all 0.2s ease-in;
 
}

.drop-icon:hover{
    font-weight: 500;
    transform: scale(1.1);
}

.justify-content-right
{
    justify-content: right;
}

body .m-checkbox>input:disabled ~ span:after {
    border-color: #7281a4;
}

body .m-checkbox > input:disabled ~ span {
    opacity: 1;
}

html .article-content a {
    outline: none !important;
    color: #007ad9;
    text-decoration: underline;
    font-weight: bold;
}


.p-accordion .p-accordion-tab.p-accordion-tab-active .p-accordion-toggle-icon,
.p-accordion .p-accordion-tab:not(.p-accordion-tab-active) .p-accordion-toggle-icon {
    transform: rotate(0deg) !important;
}
